<template>
  <div class="supplier__map" id="supplierMap"></div>
</template>

<script>
import mapStyle from '../../../lib/google-maps-style.json';
// SVG
import pinMarker from '../../../img/pin-marker.svg';

export default {
  props: {
    supplier: {
      default: {},
    },
  },
  data() {
    return {
      svg: {
        pinMarker,
      },
    };
  },
  computed: {
    coordinates() {
      if (this.supplier.coordinates) {
        return {
          latitude: this.supplier.coordinates.split(', ')[0],
          longitude: this.supplier.coordinates.split(', ')[1],
        };
      } else {
        return null;
      }
    },
  },
  methods: {
    // Google Maps
    async initMap() {
      if (!this.coordinates) return;

      await this.$store.countries.loadGMaps();

      // Map position
      const mapPosition = new google.maps.LatLng(this.coordinates.latitude, this.coordinates.longitude);

      // Main options
      const options = {
        center: mapPosition,
        zoom: 14,
        styles: mapStyle,
        disableDefaultUI: true,
        zoomControl: true,
        backgroundColor: '#e3eaf3',
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_TOP,
        },
        fullscreenControl: true,
      };

      // Init map
      const map = new google.maps.Map(document.getElementById('supplierMap'), options);

      // Marker
      const markerIcon = () => {
        let icon = window.btoa(this.svg.pinMarker);
        return {
          url: `data:image/svg+xml;base64,${icon}`,
          scaledSize: new google.maps.Size(30, 30),
          anchor: new google.maps.Point(15, 30),
        };
      };
      const marker = new google.maps.Marker({
        position: mapPosition,
        map: map,
        icon: markerIcon(),
      });
    },
  },
  mounted() {
    this.initMap();
  },
  watch: {
    '$route.fullPath'() {
      setTimeout(() => {
        this.initMap();
      }, 300);
    },
  },
};
</script>

<style lang="scss">
@use 'sass:math';
</style>
